import React from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import PhotoOne from '../images/max1.jpeg';
import PhotoTwo from '../images/max2.jpeg';
import PhotoThree from '../images/max3.jpeg';
import PhotoFour from '../images/max4.jpeg';

const Services = () => {
  const servicesItems = [
    {
      image: PhotoOne, // Replace with image paths
      title: 'Hoarding Design and Fabrication',
      description: 'We create eye-catching hoarding designs that grab attention and leave a lasting impression. Our team of skilled designers will work with you to understand your brand and target audience, and create a design that is both visually appealing and effective.',
    },
    {
      image: PhotoTwo,
      title: 'Strategic Location Selection',
      description: 'We help you reach your target audience by strategically placing your hoardings in high-traffic locations along major highways. Our team has a deep understanding of traffic patterns and demographics, ensuring your message is seen by the right people.',
    },
    {
      image: PhotoThree, // Replace with image paths
      title: 'Permitting and Approvals',
      description: 'We handle the complex process of obtaining permits and approvals from government agencies to ensure your hoardings can be displayed legally. This saves you time and hassle, allowing you to focus on your core business.',
    },
    {
      image: PhotoFour,
      title: 'Installation and Maintenance',
      description: 'Our experienced team will safely and efficiently install your hoardings at all locations. We also offer ongoing maintenance services to ensure your hoardings remain in top condition throughout the campaign.',
    },
    // Add more portfolio items as needed
  ];

  return (
    <Container sx={{maxWidth: '1400px !important'}}>
      <Typography variant="h3" component="h1" sx={{ fontWeight: 700, fontSize: '2.2rem' }}>
        Our Services
      </Typography>
      <br />
      <Grid container spacing={3}>
        {servicesItems.map((item) => (
          <Grid item xs={12} md={6} key={item.title}>
            <Card>
              <CardMedia
                component="img"
                image={item.image}
                alt={item.title}
                height="200"
              />
              <CardContent>
                <Typography variant="h5" component="h5">
                  {item.title}
                </Typography>
                <Typography variant="body1" component="p">
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Services;
