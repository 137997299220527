import React from 'react';
import { Container, Typography } from '@mui/material';

const AboutUs = () => {
  return (
    <Container sx={{maxWidth: '1400px !important'}}>
      <Typography variant="h3" component="h1" sx={{ fontWeight: 700, fontSize: '2.2rem' }}>
        About Us
      </Typography>
      <br />
      <Typography variant="body1" component="p">
        Max Advertisement is a leading provider of highway advertising solutions in India.
        We have a proven track record of helping brands achieve maximum visibility and reach with our strategically placed hoardings.
      </Typography>
    </Container>
  );
};

export default AboutUs;
