import React from "react";
import { Typography, TextField, Button, Container, Box } from "@mui/material";

function Contact() {
  return (
    <Container sx={{maxWidth: '1400px !important'}}>
      <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 700, fontSize: '2.2rem' }}>
        Contact Us
      </Typography>
      <form>
        <TextField label="Name" variant="outlined" fullWidth margin="normal" />
        <TextField label="Phone" variant="outlined" fullWidth margin="normal" />
        <TextField label="Email" variant="outlined" fullWidth margin="normal" />
        <TextField
          label="Message"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={3}
        />
        <Button variant="contained" color="primary" type="submit" sx={{ mt: 0.5 }}>
          Send Message
        </Button>
      </form>
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="body1" component="p" sx={{ mb: 1 }}>
          or
        </Typography>
        <Typography variant="body1" component="p" sx={{ mb: 0.5 }}>
          Call us: 
          <br />
          +91 98962 12304 (Tejbir Singh)
          <br />
          +91 92159 00901 (Ravinder Singh)
        </Typography>
        <Typography variant="body1" component="p">
          Email: <a href="mailto:maxadvertisement0330@gmail.com" style={{ textDecoration: 'none', color: 'inherit' }}>maxadvertisement0330@gmail.com</a>
        </Typography>
      </Box>
    </Container>
  );
}

export default Contact;
