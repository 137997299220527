import React from 'react';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Clients from './components/Clients';
import { ThemeProvider, createTheme } from '@mui/material';
import Header from './components/Header';

const theme = createTheme({
  palette: {
    primary: {
      main: '#007bff', // Your primary color
    },
    secondary: {
      main: '#ffc107', // Your secondary color
    },
  },
});


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Header />
        <Home />
        <br />
        <br />
        <AboutUs />
        <br />
        <br />
        <Clients />
        <br />
        <br />
        <Services />
        <br />
        <br />
        <Contact />
        <br />
        <br />
        <Footer />
      </div>
      {/* <Router>
        <Header />
        <Container>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Container>
        <Footer />
      </Router> */}
    </ThemeProvider>
  );
};

export default App;
