import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import Logo1 from '../images/Haldirams-Logo.png';
import Logo2 from '../images/Zomato-logo.png';
import Logo3 from '../images/Swiggy-logo.png';
import Logo4 from '../images/McDonalds-Logo.png';
import Logo5 from '../images/Nestle-Logo.png';
import Logo6 from '../images/Subway-Logo.png';
import Logo9 from '../images/Bikanervala-logo.png';
import Logo10 from '../images/The-vivaan-hotel-logo.jpg';
import Logo11 from '../images/MAXHEALTH-Logo.png';
import Logo12 from '../images/Suvidha-Logo.png';
import Logo13 from '../images/Woodland-Logo.png';
import Logo14 from '../images/Apollo-Tyres-Logo.png';
import Logo15 from '../images/Big-Bazaar-Logo.jpg';
import Logo16 from '../images/Tanishq-Logo.png';
import Logo18 from '../images/Fateh-Chand-Logo.png';
import Logo19 from '../images/Hdfc-Logo.png';
import Logo20 from '../images/ICICI-Logo.png';
import Logo21 from '../images/KFC-Logo.png';
import Logo22 from '../images/Samsung-Logo.png';
import Logo23 from '../images/Wonder-Cement-Logo.png';
import Logo24 from '../images/Tms-Logo.jpg';
import Logo25 from '../images/Piet-Logo.png';
import Logo26 from '../images/Patanjali-Logo.png';
import Logo27 from '../images/Redtape-Logo.png';
import Logo28 from '../images/Puma-Logo.png';

const Clients = () => {
  const logos = [Logo1, Logo2, Logo3, Logo4, Logo5, Logo6, Logo9,
    Logo10, Logo11, Logo12, Logo13, Logo14, Logo15, Logo16, Logo18, Logo19, Logo20
    ,Logo21, Logo22, Logo23, Logo24, Logo25, Logo26, Logo27, Logo28];

  return (
    <Container sx={{ maxWidth: '1400px !important' }}>
      <Typography variant="h3" component="h1" sx={{ fontWeight: 700, fontSize: '2.2rem', mb: 4 }}>
        Happy Clients
      </Typography>
      <Grid container spacing={4}>
        {logos.map((logo, index) => (
          <Grid item xs={6} sm={4} md={2.4} key={index}>
            <Box
              component="img"
              src={logo}
              alt={`Client logo ${index + 1}`}
              sx={{
                width: '100%',
                height: '100%',
                maxWidth: '150px',
                maxHeight: '100px',
                objectFit: 'contain',
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Clients;
