import React from 'react';
import { Container, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center', paddingTop: 4, paddingBottom: 4 }}>
      <Typography variant="body2" component="p">
        Copyright &copy; {new Date().getFullYear()} Max Advertisement Agency
      </Typography>
    </Container>
  );
};

export default Footer;
