import React from "react";
import { Container, Typography, Box } from "@mui/material";
// import HoardingImage from "../images/Hoarding.jpg";

function Home() {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage:
          'url("https://images.unsplash.com/photo-1586964627779-889f06e1e894?q=80&w=3174&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")', // Add your image URL here
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* <Box
        component="img"
        src={HoardingImage}
        alt="Hoarding"
        sx={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translateX(-50%)",
          width: "80%",
          maxWidth: "600px",
          boxShadow: 3,
          borderRadius: 1,
        }}
      /> */}
      <Container
        sx={{
          // backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: 4,
          borderRadius: 2,
          textAlign: "center",
          marginTop: "-180px",
          // background: "-webkit-linear-gradient(45deg, #FFA07A 30%, #06c 90%)",
          // WebkitBackgroundClip: "text",
          // WebkitTextFillColor: "transparent",
        }}
      >
        <Typography variant="h3" component="h1" gutterBottom>
          Max Advertisement
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#6e6e73",
          }}
        >
          We specialize in setting up hoardings on highways.
        </Typography>
      </Container>
    </Box>
  );
}

export default Home;
